
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin.js";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "user-updateProfile",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      api_url: "",
      load: false,
      employee: {
        id: "",
        name: "",
        designation: "",
        nid: "",
        passport: "",
        driving_license: "",
        dob: "",
        email: "",
        mobile: "",
        present_address: "",
        present_district: "" as any,
        permanent_address: "" as any,
        permanent_district: "" as any,
        father_name: "",
        mother_name: "",
        employee_image: "" as any,
        religion: "",
        gender: "",
      },

      userInfo: {
        employee: {
          entity_organogram: [],
        } as any,
      },
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      loading: false,
      url: "",
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.load = true;
    await this.getDistrictForEmp();
    this.userInfo = VueCookieNext.getCookie("_seip_user");

    this.emitter.on("employee_update_done", async (data) => {
      await this.getEmployeeProfile();
    });
    await this.getEmployeeProfile();
  },
  methods: {
    employeePhoto(e) {
      this.employee.employee_image = e.target.files[0];
      this.url = URL.createObjectURL(this.employee.employee_image);
    },

    async formSubmit() {
      let formData = new FormData();
      formData.set("id", this.employee.id);
      formData.set("name", this.employee.name);
      formData.set("designation", this.employee.designation);
      formData.set("nid", this.employee.nid);
      formData.set("passport", this.employee.passport);
      formData.set("driving_license", this.employee.driving_license);
      formData.set("father_name", this.employee.father_name);
      formData.set("mother_name", this.employee.mother_name);
      if (this.employee.employee_image) {
        formData.set("employee_image", this.employee.employee_image);
      }
      if (this.employee.dob) {
        formData.set("dob", this.employee.dob);
      }
      formData.set("email", this.employee.email);
      formData.set("mobile", this.employee.mobile);
      formData.set("present_address", this.employee.present_address);
      if (this.employee.present_district) {
        formData.set("present_district", this.employee.present_district);
      }
      if (this.employee.permanent_district) {
        formData.set("permanent_district", this.employee.permanent_district);
      }
      formData.set("permanent_address", this.employee.permanent_address);
      formData.set("religion", this.employee.religion);
      formData.set("gender", this.employee.gender);
      this.loading = true;
      await ApiService.post("configurations/employee/update", formData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.data.status == "success") {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.emitter.emit("employee_update_done", true);
            });
          } else {
            // let err = "";
            // for (const field of Object.keys(response.data.message)) {
            //   err += response.data.message[field][0] + "<br>";
            // }
            Swal.fire({
              title: "Please check all the required field",
              html: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
    async getDistrictForEmp() {
      await ApiService.get("configurations/geo_district/list")
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEmployeeProfile() {
      this.load = true;
      await ApiService.get(
        "configurations/employee/show/" + this.userInfo.employee.id
      )
        .then((response) => {
          this.load = false;
          this.employee = response.data.data;
          this.employee.present_district =
            response.data.data.present_district.id;
          this.employee.permanent_district =
            response.data.data.permanent_district.id;
          this.employee.employee_image =
            response.data.data.attach_file_info?.file_path;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const ProfileSchema = Yup.object().shape({
      // employeeEmail: Yup.string()
      //   .email("Must be valid email")
      //   .required()
      //   .label("Email"),
      // //presentDistrict: Yup.string().required().label("Present District"),
      // name: Yup.string().required().label("Name"),
      // // designation: Yup.string().required().label("Designation"),
      // gender: Yup.string().required().label("Gender"),
      // mobile: Yup.number().required().min(11).label("Mobile"),
      // dob: Yup.string().required().label("Date of Birth"),
      // password: Yup.string()
      //   .required()
      //   .matches(
      //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      //   )
      //   .label("Password"),
      // confirmPassword: Yup.string()
      //   .oneOf([Yup.ref("password"), null], "Password didn't match!")
      //   .label("Confirm Password"),
    });
    return {
      ProfileSchema,
    };
  },
});
